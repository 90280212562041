.App {
  text-align: center;
}

html {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.custom-scroll *::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

* {
  margin: 0;
}

.chart-title {
  position: absolute;
  padding: 1rem 2rem;
  top: 0;
  left: 0;
}

p, h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

.weight--600 {
  font-weight: 600;
}

.weight--500 {
  font-weight: 500;
}

.weight--400 {
  font-weight: 400;
}

.nowrap {
  white-space: nowrap;
}

.color--grey {
  color: grey;
}

.color--red {
  color: red;
}

.border-radius--05 {
  border-radius: .5rem;
}

.padding--1-2 {
  padding: 1rem 2rem;
}

.shadow--card {
  box-shadow: 0px 1px 9px -7px rgb(49 49 49);
}

.border--light {
  border: 1px solid #eee
}

.back--white {
  background: white;
}

.text--center {
  text-align: center;
}

h1, .h1 {
    font-size: 2rem;
    color: #606060
  }
h2, .h2{
    font-size: 1.5rem;
    color: #606060
  }
h3, .h3{
    font-size: 1.17rem;
    color: #606060
  }
h4, .h4{
    font-size: 1rem;
    color: #606060
  }
h5, .h5{
    font-size: .83rem;
    color: #606060
  }
h6, .h6{
    font-size: .67rem;
    color: #606060
  }
p, .p{
    font-size: 1rem;
    color: #606060
  }
.flex{
    display: flex
  }

.column{
    flex-direction: column
  }

.gap--25{
    gap: .25rem
  }

.gap--05{
    gap: .5rem
  }

.gap--75{
    gap: .75rem
  }

.gap--1{
    gap: 1rem
  }

.gap--2{
    gap: 2rem
  }

.gap--3{
    gap: 3rem
  }

.gap--4{
    gap: 4rem
  }

.basis--0{
    flex: 0
  }

.basis--1{
    flex: .1
  }

.basis--2{
    flex: .2
  }

.basis--3{
    flex: .3
  }

.basis--4{
    flex: .4
  }

.basis--5{
    flex: .5
  }

.basis--6{
    flex: .6
  }

.basis--7{
    flex: .7
  }

.basis--8{
    flex: .8
  }

.basis--9{
    flex: .9
  }

.basis--10{
    flex: 1
  }

.basis--30 {
  flex: 3;
}

.align--center{
    align-items: center
  }

.self--center{
    align-self: center
  }

.justify--center{
    justify-content: center
  }

.justify--between {
  justify-content: space-between
}

.width--10{
    width: 100%
  }

.height--10{
    height: 100%
  }

.overflow--scroll{
    overflow: scroll

  }
.overflow--hidden{
    overflow: hidden

  }
.pointer{
    cursor: pointer

  }
.white{
    color: white

  }
.grey{
    color: grey

  }
.color--main{
    color: #C3124D

  }
.pos-relative{
    position: relative

  }
.pos-absolute{
    position: absolute

  }
.right{
    right: 0

  }
.absolute--center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%)
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px
}

*::-webkit-scrollbar-track {
  background-color: #ffffff00
}


*::-webkit-scrollbar-thumb {
  background-color: #00000038;
  border-radius: 20px
}